.modal-header{
    padding: 5px 15px;
    background-color: #333;
}
.modal-title{
    font-size: 18px;
    color: #fff;
}
.modal-footer{
    padding: 5px 15px;
}

.actionBtnContainer{
    display: flex;
    align-items: center;
}

.actionBtnContainer > button{
    border: 0;
    margin: 0 5px;
    font-size: 14px;
}